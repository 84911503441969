function imageGallery() {
    $(".block-image_gallery .block-img-wrapper-inner").each(function(){
        $(this).fancybox({
            'transitionOut' : 'fade',
            'showCloseButton' : 'true',
            'onClosed' : function(){
                $(this).css('display','block');
            }
        });
    });
    //image_gallery
    var bilderImageSlider = jQuery('.block-image_gallery.karussell_layout');
    bilderImageSlider.owlCarousel({
        nav: true,
        navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],

        loop: true,
        dots: false,
        autoplay: false,
        smartSpeed: 750,
        stagePadding: 0,
        items: 1,
        lazyLoad: true,
        responsive: {
            0: {
                items: 3
            },
            992: {
                items: 3
            },
            1440: {
                items: 4
            }
        }
    });
}

export { imageGallery };