function blogTeaser() {
    //image_gallery
    var customTeaserSlider = jQuery('.blog_teaser.karussell_layout .owl-carousel');
    customTeaserSlider.owlCarousel({
        nav: true,
        navText: ['<i class="fas fa-angle-left"></i>', '<i class="fas fa-angle-right"></i>'],
        autoHeight: true,
        loop: true,
        dots: false,
        //margin: 0.8 * parseFloat(getComputedStyle(document.documentElement).fontSize) * 2, // 0.8rem
        autoplay: false,
        smartSpeed: 750,
        stagePadding: 0,
        items: 1,
        lazyLoad: true,
        center: true,
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 3
            },
            1440: {
                items: 3
            }
        }
    });
}

export { blogTeaser };