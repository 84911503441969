function openTab() {
    // Declariere alle Variabeln
    var i, tabcontent, tablinks;
    $(".tab ").each(function(){
        var that = $(this);

        $(this).find('.tablinks').click(function (){
            $(that).siblings('.tabcontent').removeClass('active');
            $(that).find('.tablinks').removeClass('active');
            var tabName = $(this).attr('data-aim');
            tabName = tabName.replace(/\s+/g, '');
            var tab_id = '#' + tabName;
            console.log(tab_id);
            $(tab_id).addClass('active');
            $(this).addClass('active');
        });
    });
}

export { openTab };