function accordion() {
    jQuery('.acc-head').click(function (event) {
        event.preventDefault();
        if (jQuery(this).hasClass('open') ) {
            jQuery(this).toggleClass('open');
            jQuery(this).next().toggleClass('open');
        } else {
            jQuery('.acc-head').removeClass('open');
            jQuery('.acc-body').removeClass('open');
            jQuery(this).toggleClass('open');
            jQuery(this).next().toggleClass('open');
        }
        return false;
    })
}
export { accordion };