function destinationMap() {
    $('.block_pm_destination_map .destination li a').each(function(){
        var aim = $(this).attr("data-aim");
        $(this).on('mouseenter', function(e){

            $('svg #' + aim).addClass('focus');
        }).on('mouseleave', function(e){
            $('svg #' + aim).removeClass('focus');
        });
    });
}

export { destinationMap };