import jQuery from 'jquery';
import '@fancyapps/fancybox';
import 'bootstrap';
import moment from 'moment';
import 'daterangepicker';
import 'nouislider';

import { convertRemToPixels } from './partials/ConvertRem';
import { openTab } from './partials/tab';
import { lazyload } from './globals/lazyload';
import { gutenbergColumnClasses } from './globals/gutenbergColumnClasses';
import { toggleNavigation } from './layout/navigation';
import { toggleSearch } from './layout/search';
import { slider } from './partials/slider';
import { enableForm } from './partials/form';
import { catalogorder } from './block/form.js';
import { imageGallery } from './block/image_gallery.js';
import { customTeaser } from './block/custom_teaser';
import { heroSlide } from './block/hero_slide';
import { accordion } from './block/arccordion';
import { singleVideo } from './block/single_video';
import { blogTeaser } from './block/blog_teaser';
import { headersearch } from './pm_globals/pm_headersearch';

//PM
import { pmsearch } from './pm_globals/pm_search';
import { pmBooking } from './pm_layout/pm_booking.js';
import { pmItinerary } from './pm_layout/pmitinerary.js';
import { destinationMap } from './pm_block/pm_destination_map';
import { favlist } from './pm_globals/pm_favlist';
import { pm_filter } from './pm/filter';



jQuery(document).ready(_ => {

    convertRemToPixels();
    lazyload();
    gutenbergColumnClasses();
    toggleNavigation();
    toggleSearch();
    slider();
    enableForm();
    imageGallery();
    customTeaser();
    heroSlide();
    accordion();
    singleVideo();
    blogTeaser();
    openTab();
    destinationMap();
    pmItinerary();
    favlist();
    pm_filter();
    pmBooking();
    headersearch();
    catalogorder();
    pmsearch();

    // -----------------------------------------------
    // --- toggle second level filter
    // -----------------------------------------------
    $('#search-filter').on('click', '.toggle-second-level', function(e) {
        $(this).parent().toggleClass('is--open');
    });

    // ------------------------------------------------
    // --- Filter toggle
    // ------------------------------------------------
    $('#search-result').on('click', '.list-filter-close', function(e) {
        $('.content-block-list-filter').removeClass('is--open');
    });

    $('#search-result').on('click', '.list-filter-open', function(e) {
        $('.content-block-list-filter').addClass('is--open');
    });

    // -------------------------------------------------
    // --- Daterangepicker
    // -------------------------------------------------

    if ( $('[data-type="daterange"]').length > 0 ) {
        $('[data-type="daterange"]').daterangepicker({
            "ranges": {
                'Heute': [moment(), moment()],
                'Abreise in 30 Tagen': [moment().add(30, 'days'), moment().add(1, 'year')],
                'Abreise in 60 Tagen': [moment().add(60, 'days'), moment().add(1, 'year')],
                'in diesem Monat': [moment().startOf('month'), moment().endOf('month')],
                'im nächsten Monat': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
            },
            "parentEl": ".input-group-date",
            "showWeekNumbers": true,
            "autoUpdateInput": false,
            "alwaysShowCalendars": true,
            "showDropdowns": true,
            "minYear": moment().format('YYYY'),
            "maxYear": moment().add(1, 'years').format('YYYY'),
            "minDate": moment().format('DD/MM/YYYY'),
            "maxDate": moment().add(1, 'years').format('DD/MM/YYYY'),
            "showCustomRangeLabel": false,
            // "autoApply": true,
            "locale": {
                "format": "DD.MM.YYYY",
                "separator": " - ",
                "applyLabel": "anwenden",
                "cancelLabel": "abbrechen",
                "fromLabel": "Von",
                "toLabel": "Bis",
                "customRangeLabel": "Individuell",
                "weekLabel": "W",
                "daysOfWeek": [
                    "So",
                    "Mo",
                    "Di",
                    "Mi",
                    "Do",
                    "Fr",
                    "Sa"
                ],
                "monthNames": [
                    "Januar",
                    "Februar",
                    "März",
                    "April",
                    "Mai",
                    "Juni",
                    "Juli",
                    "August",
                    "September",
                    "Oktober",
                    "November",
                    "Dezember"
                ],

                "firstDay": 1,
                "buttonClasses": "btn btn-outline-secondary btn-block",
                "applyButtonClasses": "btn btn-outline-secondary btn-block",
                "cancelClass": "btn-default",
                "cancelLabel": 'Abbrechen'
            },
            /*"startDate": moment().startOf('hour'),
            "endDate": moment().startOf('hour').add(64, 'hour')*/
        }, function(start, end, label) {


            //console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        });

        $('[data-type="daterange"]').on('apply.daterangepicker', function(ev, picker) {
            $(this).val(picker.startDate.format('DD.MM.') + ' - ' + picker.endDate.format('DD.MM.YYYY'));

            // build the a pm ready query string
            $(this).data('value', picker.startDate.format('YYYYMMDD') + '-' + picker.endDate.format('YYYYMMDD'));

            $(this).trigger('change');
        });

        $('[data-type="daterange"]').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
            $(this).data('value', '');
            $(this).trigger('change');
        });

        $('[data-type="daterange"]').on('cancel.daterangepicker', function(ev, picker) {
            $(this).val('');
        });
    }

    // -- offcanvas
    var $offcanvasToggle = $('.offcanvas-toggler'),
        $offcanvasBackdrop = $('#offanvas-backdrop'),
        $offcanvasClose = $('.offcanvas-close');

    $offcanvasToggle.unbind('click');

    $offcanvasToggle.on('click', function(e) {
        e.preventDefault();

        // -- trigger modal.
        $offcanvasBackdrop.modal('show');

        var $target = $($(this).data('target'));

        $target.addClass('is--open');

        e.stopPropagation();
    });

    $offcanvasBackdrop.unbind('click');

    $offcanvasBackdrop.on('click', function(e) {
        e.preventDefault();

        $offcanvasBackdrop.modal('hide');

        e.stopPropagation();
    });


    $offcanvasClose.on('click', function() {
        $offcanvasBackdrop.modal('hide');

    });

    $offcanvasBackdrop.on('hide.bs.modal', function(e) {
        // -- reset class from offcanvas
        $('.offcanvas.is--open').removeClass('is--open');
    });


    // ------------------------------------------
    // --- Search toggle
    // ------------------------------------------
    if ( $('#search').length > 0 ) {
        var $searchToggle = $('.search-toggler'),
            $searchWrapper = $('.header-main .col-search'),
            $searchBackdrop = $('#search-backdrop');

        $searchToggle.on('click', function(e) {
            $searchBackdrop.modal('show');
            $searchWrapper.addClass('is--open');

            e.stopPropagation();
        });

        $searchBackdrop.unbind('click');

        $searchBackdrop.on('click', function(e) {
            e.preventDefault();
            console.log('test');

            $searchBackdrop.modal('hide');

            e.stopPropagation();
        });

        $searchBackdrop.on('hide.bs.modal', function(e) {
            $searchWrapper.removeClass('is--open');
        });

        $searchBackdrop.on('show.bs.modal', function(e) {
            $searchWrapper.addClass('is--open');
        });
    }

    // ------------------------------------------
    // --- autocomplete
    // ------------------------------------------
    if ( $('#search').length > 0 ) {
        var suggestions = [
            {value: 'Busreise', data: 'BUS'},
            {value: 'Flugreise', data: 'FLUG'},
            {value: 'Urlaubsreise', data: 'URL'},
            {value: 'Italien', data: 'IT'},
            {value: 'Deutschland', data: 'DE'},
            {value: 'Schweiz', data: 'SW'},
            {value: 'Frankreich', data: 'FR'},
            {value: 'Familienreise', data: 'FAM'},
            {value: 'USA', data: 'US'}
        ]

        $('#search .form-control').autocomplete({
            lookup: suggestions,
            onSelect: function (suggestion) {
                console.log('You selected' + suggestion.value + ', ' + suggestion.data);
            }
        })
    }

    // -------------------------------------------
    // --- Multiselect
    // --------------------------------------------
    if ( $('.dropdown-menu-select').length > 0 ) {

        // -- prevent dropdown close when clicked inside
        $('.dropdown-menu-select').on('click', function(e) {
            e.stopPropagation();
        });

        $('.dropdown-menu-select .filter-prompt').on('click', function(e) {
            e.preventDefault();

            $(this).parents('.dropdown').find('.dropdown-toggle').trigger('click');

            e.stopPropagation();
        })


        // -- create label text on input change, put it into span
        $('.dropdown-menu-select').on('change', 'input', function(e) {

            var placeHolderTag = $(this).parents('.dropdown').find('.selected-options'),
                placeHolderDefaultText = placeHolderTag.data('placeholder'),
                placeHolderGetText = placeHolderTag.text(),
                placeHolderOptionsText = '',
                that = $(this);

            if ( placeHolderGetText != placeHolderDefaultText ) {
                placeHolderOptionsText = placeHolderGetText;
            }

            var thatValue = that.parent().find('> label').text();
            thatValue = $.trim(thatValue);

            if ( that.prop('checked') === true ) {
                if ( placeHolderOptionsText != '' ) {
                    placeHolderOptionsText = placeHolderOptionsText + ', ' + thatValue;
                } else {
                    placeHolderOptionsText = thatValue;
                }
            } else {
                if ( placeHolderGetText.indexOf(',') != -1 ) {
                    if ( placeHolderGetText.indexOf(thatValue) == 0 ) {
                        placeHolderOptionsText = placeHolderOptionsText.replace(thatValue + ', ', '');
                    } else {
                        placeHolderOptionsText = placeHolderOptionsText.replace(', ' + thatValue, '');
                    }
                } else {
                    placeHolderOptionsText = $.trim(placeHolderDefaultText);

                }
            }

            //placeHolderTag.text(placeHolderOptionsText);

        });

        // Init on load
        $('.dropdown-menu-select input:checked').trigger('change');

    }

    /**
     * Example usage:
     * var Search = new TSAjax('/wordpress/wp-content/themes/kreka/ajax-endpoint.php');
     * Search.get('test_id', {action: 'get'});
     */

     function TSAjax(endpoint_url) {

        var _this = this;
        this.endpoint_url = endpoint_url;

        // @todo name me
        //if ( $('.js-range-slider').length > 0 ) {
            //$(".js-range-slider").ionRangeSlider({
                    //input_values_separator : '-'
                //}
            //);
        //}

        this.call = function (query_string, scrollto, total_result_span_id) {
            console.log(this.endpoint_url);
            var jqxhr = $.ajax({
                url: this.endpoint_url + '?' + query_string,
                method: 'GET',
                data: null
            })
                .done(function (data) {

                    for (var key in data.html) {
                        $('#' + key).html(data.html[key]);
                    }

                    $('.input-group.select').click(function(){
                        $(this).find('.list-filter-box.category-tree').toggleClass('d-block');
                    });

                    if(total_result_span_id != null){

                        var total_count_span =  $(total_result_span_id);

                        if(data.count == 1){
                            total_count_span.html(data.count + ' ' + total_count_span.data('total-count-singular'))
                        }else if(data.count > 1 || data.count == 0){
                            total_count_span.html(data.count + ' ' + total_count_span.data('total-count-plural'))
                        }else{
                            total_count_span.html(total_count_span.data('total-count-default'));
                        }


                    }
                    if(data.count > 0){
                        $('.resultcount').html(data.count);
                    }

                    if(scrollto){
                        $('html, body').animate({
                            scrollTop: ($(scrollto).offset().top)
                        },500);
                    }

                    window.history.pushState(null, '', window.location.pathname + '?' + query_string);




                    var fav = localStorage.getItem("favlist");

                    if(fav == null){
                        fav = "";
                    }
                    if(fav != null){
                        fav = "?fav=" + fav;
                    }


                var favArray = [];
                var newload = true;

                //get favlist
                var favlist = localStorage.getItem('favlist');

                if(favlist != null){
                    var favsplit = favlist.split(",");
                    var favsplitcount = favlist.split(",");
                } else {
                    var favsplit = false;
                }

                if(favsplitcount.length){
                    var favsplitcountArray = jQuery.grep(favsplitcount, function(value) {
                        return value != "";
                    });

                }

                var favLen = favsplit.length;
                var favCount = favsplitcountArray.length;

                $('header .favorite .counter').html(favCount);

                console.log(favCount);

                $('button.favorite').each(function(){
                    var _this = $(this);
                    var travelid = $(this).attr('data-mediaid');

                    var remove = false;

                    var i = 0;

                    //if this travel-id is in list
                    if(favlist != null) {
                        for (i = 0; i < favLen; i++) {
                            if (favsplit[i] == travelid) {
                                _this.addClass('active');

                            }
                        }
                    }

                    _this.click(function() {

                        favlist = localStorage.getItem('favlist');
                        if(favlist != null){
                            favsplit = favlist.split(",");
                        } else {
                            favsplit = false;
                        }


                        favLen = favsplit.length;


                        remove = false;

                        i = 0;

                        if(favlist != null) {
                            for (i = 0; i < favLen; i++) {
                                if (favsplit[i] == travelid) {
                                    remove = true;

                                }
                            }
                        }

                        if (remove == false) {

                            $(this).addClass('active');
                            favArray.push(travelid);
                            if ((favLen > 0) && (newload == true)) {
                                favArray.push(favlist);
                            }
                            localStorage.setItem('favlist', favArray);
                        }

                        if(remove == true){
                            var removeItem = travelid;

                            $(this).removeClass('active');

                            if(favsplit.length){
                                favArray = jQuery.grep(favsplit, function(value) {
                                    return value != removeItem;
                                });
                                localStorage.setItem('favlist', favArray);
                            }
                        }


                        //Count

                        favlist = localStorage.getItem('favlist');

                        if(favlist != null){
                            favsplitcount = favlist.split(",");
                        } else {
                            favsplitcount = false;
                        }

                        if(favsplitcount.length){
                            favsplitcountArray = jQuery.grep(favsplitcount, function(value) {
                                return value != "";
                            });

                        }

                        favCount = favsplitcountArray.length;

                        $('header .favorite .counter').html(favCount);

                        newload = false;
                    });
                });




                })
                .fail(function () {
                    console.log('ajax error');
                });
        }

        this.pagination = function () {

            // @todo load item list at the end of the existing list
            $("#search-result").on('click', ".page-link", function (e) {
                var href = $(this).attr('href').split('?');
                var query_string = href[1];
                _this.call(query_string, '#search-result');
                e.preventDefault();
            });

        }

        this.buildSearchQuery = function(form){

            var query = [];

            // the object type
            var id_object_type = $('input[name=pm-ot]').val();
            if(id_object_type && id_object_type != ''){
                query.push('pm-ot='+id_object_type);
            }

            // checkboxes
            var selected = [];

            $(form).find(".category-tree input:checked").each(function(){

                var id_parent = $(this).data('id-parent');
                var id = $(this).data('id');
                var name = $(this).data('name');

                if(!selected[name]){
                    selected[name] = [];
                }

                var i = selected[name].indexOf(id_parent);
                if(i > -1){
                    // parent ist vorhanden entferne
                    selected[name].splice(i, 1);
                }

                var i = selected[name].indexOf(id);
                if(i == -1){
                    // ist nicht vorhanden, hinzufügen
                    selected[name].push(id);
                }

            });

            var delimiter = ',';

            /* @todo
            if (selected_item.data('behaivor') == 'AND'){
                var delimiter = '+';
            }
            */

            var key;
            for(key in selected){
                query.push('pm-c['+key+']='+selected[key].join(delimiter));
            }

            // check and set price-range
            var price_range = $('input[name=pm-pr]').val();
            if(price_range && price_range != ''){
                query.push('pm-pr='+price_range);
            }

            // check and set date-range
            var date_range = $('input[name=pm-dr]').data('value');
            if(date_range && date_range != ''){
                query.push('pm-dr='+date_range);
            }

            // check and set search term
            var search_term = $('input[name=pm-t]').val();
            if(search_term && search_term != ''){
                query.push('pm-t='+search_term);
            }

            var order = $('select[name=pm-o]').val();

            if(order && order != ''){
                query.push('pm-o='+order);
            }

            // Build the Query
            var query_string;
            query_string = query.join('&');

            return query_string;
        }

        this.filter  = function(){

            $("#search-filter").on('change', ".list-filter-box input, .list-filter-box select", function (e) {
                var form = $(this).closest('form');
                var query_string =_this.buildSearchQuery(form);
                _this.call(query_string, '#search-result');
                e.preventDefault();
            });


            $("#search-filter").on('click', ".list-filter-box-submit", function (e) {
                var form = $(this).closest('form');
                var query_string =_this.buildSearchQuery(form);
                _this.call(query_string, '#search-result');
                e.preventDefault();
            });

            $('.result-list-header').on('change',  'select', function (e) {
                var form = $(this).closest('form');
                var query_string =_this.buildSearchQuery(form);
                _this.call(query_string, '#search-result');
                e.preventDefault();

            });


        }


        this.searchbox  = function(){

            /**
             * This Event checks if a input field is modified and building the query string.
             * The query string is added to the form > a.btn > href
             * If the search box is on the same site as the search result, than the ajax search query is fired
             */
            $(".search-box input, .search-box  select").on('change', function (e) {

                var form = $(this).closest('form');

                // build the query string and set him on the search button
                var query_string =_this.buildSearchQuery(form);

                var button = $(form).find('a.btn');
                var href = button.attr('href').split('?');
                button.attr('href', href[0] + '?' + query_string);

                // if we're on the same page, let fire the search
                var current_location = window.location.href.split('?');
                if(current_location[0] == href[0]){
                    _this.call( query_string, undefined, '.search-bar-total-count');
                }

                e.preventDefault();
            });

        }

    };

    var Search = new TSAjax('/wp-content/themes/kreka/pm-ajax-endpoint.php');
    Search.pagination();
    Search.filter();
    Search.searchbox();

    var headerheight = $('header').outerHeight();
    $('body').css('padding-top', headerheight);

});
jQuery(window).ready(_ => {
    var headerheight = $('header').outerHeight();
    $('body').css('padding-top', headerheight);
});