function catalogorder() {
    $('.form-catalog .catalog-label').each(function (){
        if ($(this).length != 0) {
            $(this).click(function(){

                if ($(this).find('input').is(':checked')){
                    $(this).find('input').removeAttr('checked');
                } else {
                    $(this).find('input').attr('checked', '');
                }

            });
        }
    });


    $('.block-form').each(function(){

        var activeform = $(window.location.hash);

        if(activeform.length){
            activeform.removeClass('d-none');

            var formtop = activeform.offset().top;
            window.scroll({
                top: formtop - 100,
                behavior: 'smooth'
            });
            console.log(activeform.offset().top);
        }
    });
};
export { catalogorder };