function heroSlide() {
    $('.hero_slider .owl-carousel').owlCarousel({
        items: 1,
        nav: false,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        dots: true,

        loop: true,
        margin: 0,
        lazyload: true,
        responsive:{
            0:{
                items: 1
            },
            992:{
                items: 1
            },
            1200:{
                items: 1
            }
        }
    });

    $('.hero_slider .media-wrapper .youtube.hasid').each(function(){
        var data_link = $(this).attr("data-link");
        //data_link = data_link.replace("https://youtu.be/","");
        $(this).click(function(){
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + data_link +'?rel=0&autoplay=1&color=white&disablekb=1&showinfo=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            setTimeout(function(){
                $(this).children('img').remove();
            }, 2000);

            $(this).parents('.hero-teaser').addClass("play");
            $(this).parents('.media-wrapper').addClass("isaktiv");

        });
    });
};
export { heroSlide };