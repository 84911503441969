function pmBooking() {

    var detailbooking = $('#content-block-detail-booking').offset();
    if(detailbooking !== undefined){
        var jumpmark = detailbooking.top - 200;

        $('.btn-booking-jump').each(function(){
            $(this).click(function(){
                $([document.documentElement, document.body]).animate({
                    scrollTop: jumpmark
                }, 2000);
            });
        });
    }

    var bookingMonthSelect  = $('.detail-booking-body .input-group.select .form-select[aria-label="month"]');
    var bookingHousingSelect  = $('.detail-booking-body .input-group.select .form-select[aria-label="housetype"]');

    if((bookingMonthSelect.length) || (bookingHousingSelect.length)) {
        var selectedMonth = bookingMonthSelect.val();
        var selectedHousing = bookingHousingSelect.val();

        $('.result.row[data-month="' + selectedMonth + '"][data-housing="' + selectedHousing + '"]').addClass('show');

        $('.body-select .form-select').change(function(){
            $('.result.row').removeClass('show');
            selectedMonth = bookingMonthSelect.val();
            selectedHousing = bookingHousingSelect.val();
            $('.result.row[data-month="' + selectedMonth + '"][data-housing="' + selectedHousing + '"]').addClass('show');

        });

    }

    var bookingRequestBtn  = $('.layout-detail-main #request-booking-btn');

    bookingRequestBtn.click(function(){
        var jumpmarkRequest = $('#request-booking');
        jumpmarkRequest.removeClass('d-none');
        jumpmarkRequest = jumpmarkRequest.offset().top - 200;
        $([document.documentElement, document.body]).animate({
            scrollTop: jumpmarkRequest
        }, 2000);
    })

}

export { pmBooking }