function favlist() {
    function favbutton(){
        var favArray = [];
        var newload = true;

        //get favlist
        var favlist = localStorage.getItem('favlist');

        if(favlist != null){
            var favsplit = favlist.split(",");
            var favsplitcount = favlist.split(",");
        } else {
            var favsplit = false;
        }

        if(favsplitcount){
            var favsplitcountArray = jQuery.grep(favsplitcount, function(value) {
                return value != "";
            });

        }

        if(favsplit){
            var favLen = favsplit.length;
        }
        if(favsplitcountArray){
            var favCount = favsplitcountArray.length;
        }

        $('header .favorite .counter').html(favCount);

        console.log(favCount);

        $('button.favorite').each(function(){
            var _this = $(this);
            var travelid = $(this).attr('data-mediaid');

            var remove = false;

            var i = 0;

            //if this travel-id is in list
            if(favlist != null) {
                for (i = 0; i < favLen; i++) {
                    if (favsplit[i] == travelid) {
                        _this.addClass('active');

                    }
                }
            }

            _this.click(function() {

                favlist = localStorage.getItem('favlist');
                if(favlist != null){
                    favsplit = favlist.split(",");
                } else {
                    favsplit = false;
                }


                if(favsplit){
                    favLen = favsplit.length;
                }

                remove = false;

                i = 0;

                if(favlist != null) {
                    for (i = 0; i < favLen; i++) {
                        if (favsplit[i] == travelid) {
                            remove = true;

                        }
                    }
                }

                if (remove == false) {

                    $(this).addClass('active');
                    favArray.push(travelid);
                    if ((favLen > 0) && (newload == true)) {
                        favArray.push(favlist);
                    }
                    localStorage.setItem('favlist', favArray);
                }

                if(remove == true){
                    var removeItem = travelid;

                    $(this).removeClass('active');

                    if(favsplit.length){
                        favArray = jQuery.grep(favsplit, function(value) {
                            return value != removeItem;
                        });
                        localStorage.setItem('favlist', favArray);
                    }
                }


                //Count

                favlist = localStorage.getItem('favlist');

                if(favlist != null){
                    favsplitcount = favlist.split(",");
                } else {
                    favsplitcount = false;
                }

                if(favsplitcount){
                    favsplitcountArray = jQuery.grep(favsplitcount, function(value) {
                        return value != "";
                    });

                }
                if(favsplitcountArray){
                    favCount = favsplitcountArray.length;
                }

                $('header .favorite .counter').html(favCount);

                newload = false;
            });
        });
    }

    favbutton();


    var fav = localStorage.getItem("favlist");

    if(fav == null){
        fav = "";
    }
    if(fav != null){
        fav = "?fav=" + fav;
    }

    //$.ajax({
        //url: "/wp-content/themes/kreka/template-parts/pm-fav/fav-count.php" ,
        //type: "GET",
        //data: null,
    //}).done(function (data) {
        //favlist = localStorage.getItem('favlist');
        //console.log(favlist);
    //});


    if($(location).attr('pathname') == /merkliste/){

        $.ajax({
            url: "/wp-content/themes/kreka/template-parts/pm-fav/fav-ajax.php" + fav ,
            type: "GET",
            data: null,
        }).done(function (data) {

            $('#favlist-content').html(data);

            favbutton();

            $('button.favorite').click(function(){
                $(this).parents('.item.fav-teaser ').remove();

                var favitems =  $('#favlist-content .item.fav-teaser ');

                if (favitems.length == 0){
                    $('#favlist-content').append('<p class="notravel">Keine Reise gefunden</p>');
                }
            });
        }).fail(function (data) {
            console.log('AJAX ERROR: ' + data.statusText);
        });
    }

}

export { favlist };