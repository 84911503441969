function toggleNavigation() {

    var winwidth = window.innerWidth;

    if(winwidth < 992){
        $('.nav-item.dropdown').prepend('<i class="far fa-caret-circle-down"></i>');
        $('.nav-item.dropdown i').click(function(){
            $(this).siblings('ul').toggleClass('open');
        });

    }
}
export { toggleNavigation };
