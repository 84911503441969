function pmItinerary() {

    $('.layout-detail-itinerary').each(function (){

        var x=0;

        $(this).find(".Beschreibung_Head").each(function(){

            x = x+1;
            var text = $(this).text();
            var text_full = text;

            var res = text.split(":");

            var testreplace = res[0].replace('.', ' ');

            var testres = testreplace.split(" ")


            if(testres[1] == "undefined"){
                testres[1] = "";
            }

            if((testres[0] == "TAG") || (testres[0] == "Tag") || (testres[0] == "tag") || ($.isNumeric(testres[0]))){
                $(this).parents("p").replaceWith('<div class="day item' + x + '"><span class="btn">' + res[0] + '</span><span class="btn"><i class="far fa-caret-circle-down"></i></span></div><p class="h5">' + res[1] + '</p>');
            } else {
                $(this).parents("p").replaceWith('<div class="other_desc"><p class="h5">'+text_full+'</p></div>');
            }
        });

        x=0;

        $(this).find(".day").each(function (){

            var _this = $(this);
            x = x+1;

            _this.attr("data-acc", x);
            var dataacc = _this.attr("data-acc");

            _this.nextUntil("div").addClass("cont cont_"+ dataacc);

            //open first acordion element

            if(_this.attr("data-acc") == 1){
                _this.nextUntil("div").addClass("show");
                _this.addClass('open');
            }

            //open acordion element on click an hide other

            _this.click(function(){
                //$('.layout-detail-itinerary p').removeClass("show");
                //$('.layout-detail-itinerary .day').removeClass('open');

                _this.nextUntil("div").toggleClass("show");
                _this.toggleClass('open');
            });
        });

    });
}

export { pmItinerary }
