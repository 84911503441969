function pm_filter() {
    $('.layout-search .input-group').each(function(){
        $(this).click(function(){
            $(this).find('.list-filter-box').toggle();
        });
    });

    var priceRange = $('#price-range');
    var inputPriceRange = $('.js-range-slider');
    var checkboxtoggle = $('.multi-level-checkboxes .seccond-level-toggle');

    $(checkboxtoggle).click(function(){
        console.log($(this).parents('.form-label-wrapper').siblings('.list-filter-second-level'));
        $(this).parents('.form-label-wrapper').siblings('.list-filter-second-level').toggleClass('show');
    });

    if (inputPriceRange.length != 0) {
        var minPrice = inputPriceRange.attr('data-min');
        var maxPrice = inputPriceRange.attr('data-max');
        console.log(minPrice);
    }
    if (priceRange.length != 0) {
        var noUiSlider = require("nouislider");
        noUiSlider.create(priceRange[0], {
            start: [250, 3850],
            connect: true,
            tooltips: false,
            decimals: 0,
            range: {
                'min': parseInt(minPrice),
                'max': parseInt(maxPrice)
            }
        });

        var inputNumberMin = document.getElementById('value-min');
        var inputNumberMax = document.getElementById('value-max');
        priceRange[0].noUiSlider.on('update', function (values, handle) {

            var value = values[handle];

            if (handle) {
                inputNumberMax.value = Math.round(value);
                $( inputNumberMax ).text( inputNumberMax.value + " €" );
            } else {
                inputNumberMin.value = Math.round(value);
                $( inputNumberMin ).text( inputNumberMin.value + " € -" );
            }

            if (inputPriceRange.length != 0) {
                inputPriceRange.val(inputNumberMin.value + '-' + inputNumberMax.value);
            }

        });
    }
}

export { pm_filter };