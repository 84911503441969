function singleVideo() {
    $('.block-single_video .media-wrapper .youtube').each(function(){
        $(this).click(function(){
            var data_link = $(this).attr("data-id");
            $(this).html('<iframe width="100%" height="100%" src="https://www.youtube.com/embed/' + data_link + '?rel=0&autoplay=1&color=white&disablekb=1&showinfo=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
            setTimeout(function(){
                $(this).children('img').remove();
            }, 2000);

            if ($(this).parents('.media-wrapper').siblings().hasClass('teaser-text')){
                $(this).parents('.media-wrapper').siblings('.teaser-text').css('display', 'none');
            }

            $(this).parents('.hero-teaser').addClass("play");
            $(this).addClass("isaktiv");
        });
    });
}
export { singleVideo };